import React from 'react'
import Carousel, { Dots } from "@brainhubeu/react-carousel"
import "@brainhubeu/react-carousel/lib/style.css"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"


export default () => {

    
    const data = useStaticQuery(graphql`
      query Photos {
        allFile {
          edges {
            node {
              id
              childImageSharp {
                fixed(width: 500) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
        mobile: allFile {
          edges {
            node {
              id
              childImageSharp {
                fixed(width: 300) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    `)

    return (
      <div>
        <Carousel arrows centered>
          {data.allFile.edges.map((item, index) => (
            <Img
              key={index}
              fixed={[
                {
                  ...item.node.childImageSharp.fixed,
                  media: `(min-width: 992px)`,
                },
                {
                  ...data.mobile.edges[index].node.childImageSharp.fixed,
                  media: `(max-width: 991px)`,
                },
              ]}
            ></Img>
          ))}
        </Carousel>
      </div>
    )
}